<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'
import orderService from '@/services/order'

export default {

    components: {BasketLayout, SidePanelHeader, Btn}, 
    mixins: [merchantMixin, orderMixin, mediaMixin],
    data() {
        return {
            isLoading: false,
            couponInfos: null
        }
    },
    methods: {
        async generateCoupon() {

            this.isLoading = true
            let response = await orderService.generateCoupon(this.order.public_id)

            this.couponInfos = response.data

            this.isLoading = false
        },
        sendInfos() {

            this.isLoading = true

            let created_at_date = new Date(this.order.created_at);
            created_at_date.setHours(created_at_date.getHours() + this.order.expiration_hours);
            let expirationDate = created_at_date.getTime() / 1000;
            let bonus = null
            if (this.couponInfos.bonus) {

                bonus = {
                    name: this.couponInfos.bonus.name,
                    legalLabel: this.couponInfos.bonus.legalLabel,
                    amount: this.couponInfos.bonus.amount,
                    code: this.couponInfos.bonus.code
                }
            }

            window.parent.postMessage(
            {
                type: 'order', 
                productName: this.order.label,
                amount: Math.round(this.order.price * 100),
                publicId: this.order.public_id,
                expirationDate : expirationDate,
                productImgUrl: this.order.merchant_product_img,
                coupons: this.couponInfos.coupons,
                bonusCode: this.couponInfos.bonusCode,
                bonus: bonus
            }, '*')
            
            this.isLoading = false
        },
        clickIBAN() {

            this.$router.push({name: 'ibanIframe', params: {orderId: this.orderId}})
        }
    },
    watch: {
        isOrderLoaded() {

            console.log(this.order)

            this.generateCoupon()

            this.$tracking.pageView('Validated', 'Iframe Order', {'Order ID': this.orderId})
        }
    },
    computed: {
        hasCoupons() {

            if (this.couponInfos == undefined) return false

            if (this.couponInfos.coupons == undefined) return false

            if (this.couponInfos.coupons.length == 0) return false

            return true
        },
        hasBonus() {

            if (this.couponInfos == undefined) return false

            if (this.couponInfos.bonusCode == undefined) return false

            return true
        },
        hasPromoCode() {

            if (this.merchant.id == 7764956693) return false

            return true
        },
        isCash() {

            if (this.order == undefined) return false

            if (this.order.payment_type == 'cash') return true

            return false
        },
        displayIBANMessage() {

            return this.$route.query.i == 1
        }
    },
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="''" :closeFunction="sendInfos" />
        <div v-if="isOrderLoaded" class="iframe-content">
            <div id="content-header">
                <img id="content-header-img" :src="getLocalImage('icon-tick.svg', merchantCss)" alt="">
                <h2 v-html="$t('MERCHANT.IFRAME.' + merchant.id + '.ORDER_VALIDATED')"></h2>
                <h3 id="content-header-offer" >Meilleure offre du marché : <span id="content-header-price" >{{order.price | currency}}</span></h3>
                <p id="content-header-title" >{{order.brand_name}} {{order.label}}</p>
            </div>
            <div v-if="order.brand_campaign != null" id="bonus-container">
                <div id="left-part" ></div>
                <div id="bonus-text">
                    <p><span id="bonus-price">+ {{order.brand_campaign.bonus_amount / 100}} € de bonus </span><span v-if="hasPromoCode"> via code promo</span> pour l’achat d’un {{order.brand_campaign.name}}</p>
                </div>
            </div>
            <p id="bonus-container-caption">
                Offre exclusive web, prix non garanti en magasin.
                <br>
                <span v-if="couponInfos != undefined && couponInfos.bonusCode != undefined">Non cumulable avec un autre code promo.</span>
            </p>
            <p v-if="displayIBANMessage" class="text-success">Votre IBAN a bien été enregistré <i class="bi bi-check"></i></p>
            <p v-if="isCash" class="validated-content-text">Vous recevrez le montant de votre offre de reprise et votre bonus par virement bancaire sous 14 jours après validation de votre produit.</p>
            <p v-if="hasCoupons && hasBonus" class="validated-content-text"><span class="bold">Votre carte cadeau et votre code promo* vont être automatiquement appliqués à votre commande en cours.</span> <br>Cependant vous pouvez choisir d’utiliser votre carte cadeau pour une autre commande.</p>
            <p v-if="hasCoupons && !hasBonus" class="validated-content-text"><span class="bold">Votre carte cadeau va être automatiquement appliquée à votre commande en cours.</span> <br>Cependant vous pouvez choisir d’utiliser votre carte cadeau pour une autre commande.</p>
            <p class="validated-content-text"><span class="bold">Vous allez recevoir toutes les informations par mail</span>, et également votre <span class="bold">étiquette de transport offerte</span> pour expédier votre produit repris <span class="bold">sous 7 jours.</span></p>
            <p class="validated-content-text">Le recyclage du produit permet d’économiser <span class="bold" style="color:#0C7153;">33kg de CO2.</span>  </p>
            <div v-if="order.payment_type == 'cash'">
                <p class="validated-content-text">
                    Vous pouvez fournir votre IBAN dés maintenant afin de recevoir votre paiement une fois votre reprise validée.
                    <br/>
                    Si vous ne le fournissez pas maintenant, vous serez contacté ultérieurement.
                </p>
                <btn @click="clickIBAN()" class="btn-iframe-light mt-3" :text="'Fournir son IBAN'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading" />
            </div>
            <div id="btn-validate-container">
                <btn @click="sendInfos()" class="btn-iframe-dark" :text="$t('MERCHANT.IFRAME.' + merchant.id + '.ORDER_VALIDATED_NEXT')" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading" /> 
            </div>
            <p v-if="hasBonus" id="under-btn-caption">*Non cumulable avec un autre code promo</p>
        </div>

    </basket-layout>
</template>
<style scoped>
h2 {

    font-size: 18px;
    font-weight: var(--iframe-font-weight-bold);
}

h3 {

    font-size: var(--iframe-font-size-h3);

    margin: 0;
}

#validated-content{
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
}

#layout {
    height: 100vh;
    overflow: hidden;
}

#content-header-img {

    margin-bottom: 20px;
}

#content-header-title {

    color: var(--iframe-border-grey-dark);
}

#content-header-price {

    color: var(--iframe-color-price);
    font-size: var(--iframe-font-size-h2);
    font-weight: var(--iframe-font-weight-bold);
}

#content-header {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:30px;
}

#bonus-container {

    height:66px;
    border: 1px var(--iframe-border-grey-light) solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

#bonus-container #bonus-text {

    margin:10px;    
}

#left-part {

    background-color: #0C7203;
    min-width: 4px;
    height: 100%;
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#btn-validate-container {
    margin:20px 0;
}

#bonus-container-caption {

    font-size:var(--iframe-font-size-small);
    color: var(--iframe-border-grey-dark);
    margin-top:15px;
}

.bold {
    font-weight: var(--iframe-font-weight-bold);
}

.validated-content-text{
    margin-top: 32px;
}

#bonus-price {
    color:var(--iframe-color-price);
    font-weight: var(--iframe-font-weight-bold);
}
</style>
